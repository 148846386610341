import React from "react";
import { Fade, Grid, Typography, LinearProgress } from "@mui/material"; // @mui/material로 변경
import { styled } from "@mui/system"; // styled import

// styled API를 사용하여 스타일링 적용
const Root = styled("div")({
  marginTop: 140,
  height: `calc(100vh - 136px)`,
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const Logo = styled("img")({
  top: "-5rem",
  position: "relative",
});

const OuterGrid = styled(Grid)(({ theme }) => ({
  position: "absolute",
  width: "100%",
  bottom: "100px",
  display: "flex",
  flexFlow: "column",
  padding: "0 2rem",
}));

const InnerGrid = styled(Grid)({
  height: "72px",
  display: "flex",
  flexFlow: "column",
  alignItems: "center",
  justifyContent: "center",
  fontFamily: "Roboto",
  fontSize: "16px",
  fontWeight: "normal",
  color: "rgba(0, 0, 0, 0.6)",
});

const LoadingTypo = styled(Typography)({
  width: "200px",
  height: "24px",
  fontFamily: "Roboto",
  fontSize: "16px",
  fontWeight: "normal",
  color: "rgba(0, 0, 0, 0.38)",
  textAlign: "center",
});

const LoadingBar = styled(LinearProgress)(({ theme }) => ({
  height: 4,
  backgroundColor: "#BFBFBF",
  "& .MuiLinearProgress-bar": {
    backgroundColor: "#0062b2",
  },
}));

class Loading extends React.Component {
  state = {
    completed: 0,
  };

  // 컴포넌트가 마운트되면 타이머 시작
  componentDidMount() {
    this.timer = setInterval(this.progress, 500); // 0.5초마다 progress 함수 실행
  }

  // 컴포넌트가 언마운트되면 타이머 정리
  componentWillUnmount() {
    clearInterval(this.timer);
  }

  // 로딩 상태 업데이트
  progress = () => {
    const { completed } = this.state;
    const { done, moveToNextPage } = this.props;

    // done이 true이고 completed가 100% 미만일 때
    if (done && completed < 100) {
      this.setState({ completed: 100 }); // 로딩바를 100%로 설정
    } else if (completed === 100) {
      console.log("완료");
      moveToNextPage(); // 로딩이 완료되면 페이지 전환
      clearInterval(this.timer); // 타이머 멈춤
    } else {
      const diff = Math.random() * 10; // 진행 상태를 랜덤하게 조금씩 증가
      this.setState({ completed: Math.min(completed + diff, 90) }); // 90%까지 진행
    }
  };

  render() {
    const { completed } = this.state;

    return (
      <Root>
        <Fade in timeout={1000}>
          <Logo src="/loading_logo.svg" alt="Loading Logo" />
        </Fade>
        <Fade in timeout={1500}>
          <OuterGrid container>
            <InnerGrid item>
              <LoadingTypo>Loading...</LoadingTypo>
            </InnerGrid>
            <LoadingBar variant="determinate" value={completed} />
          </OuterGrid>
        </Fade>
      </Root>
    );
  }
}

export default Loading;
