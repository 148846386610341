import React from "react";
import "./ErrPageHandler.css";

const ErrPageHandler = () => {
  return (
    <div className="error">
      <div className="error-img">
        <img src="/img/404.png" alt="404" />
      </div>
      <div className="error-msg-box">
        <p className="error-msg-code">404</p>
        <div className="error-msg">
          <p>페이지를 찾을 수 없습니다.</p>
          <p>The page you requested could not be found.</p>
        </div>
      </div>
    </div>
  );
};

export default ErrPageHandler;
