import React from "react";
import "./Main.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import queryString from "query-string";
import forkIcon from "../assets/images/fork.png";
import continueIcon from "../assets/images/continue.png";
import pickUpIcon from "../assets/images/pickUpIcon.png";
import deliveryIcon from "../assets/images/deliveryIcon.png";
import continueBar from "../assets/images/continueBar.png";
import pickUpOrderAhead from "../assets/images/pickUpOrderAhead.png";
import tableOrder from "../assets/images/tableOrder.png";
import deliveryToYourHome from "../assets/images/deliveryToYourHome.png";
import engIcon from "../assets/images/eng.png";
import HeaderContainer from "../containers/common/HeaderContainer";

const Main = ({ cartItems, setCartItems }) => {
  const location = useLocation();
  const { uuid } = useParams();

  // 쿼리 파라미터에서 tableId 추출
  const { tableId } = queryString.parse(location.search);

  const navigate = useNavigate();

  const handleTableClick = () => {
    navigate(`/${uuid}/menu?tableId=${tableId}`);
  };
  return (
    <div>
      <HeaderContainer cartItems={cartItems} setCartItems={setCartItems} />
      <div className="main">
        {/* 상단 로고 및 언어 버튼 */}
        <div className="main-top-section">
          <img src="/img2/storeLogo2.png" alt="Logo" className="main-logo" />
          <img src={engIcon} alt="Language" className="main-language" />
        </div>

        {/* 이미지 섹션 */}
        <div className="main-image-section">
          <img
            src="/img2/restaurant.png"
            alt="Restaurant"
            className="main-image"
          />
          <div className="welcome-text">
            <h3>Welcome!</h3>
            <p>Napoli's Pasta</p>
          </div>
        </div>

        {/* 진행 버튼 */}
        <div className="main-continue-section">
          <div className="main-continue-text">
            <img src={continueIcon} alt="ContinueArrow" />
            <span>Continue Ordering</span>
          </div>
          <div className="main-continue-bar">
            <img src={continueBar} alt="ContinueBar" />
          </div>
        </div>

        {/* 테이블 오더 */}
        <div className="main-table-section" onClick={handleTableClick}>
          <div className="main-table-text">
            <div className="main-table-icon">
              <img src={forkIcon} alt="Fork" />
            </div>
            <div className="main-table-p">
              <p>Eating a meal</p>
              <p>Order at Your Table</p>
            </div>
          </div>
          <div className="main-table-bar">
            <img src={tableOrder} alt="tableBar" />
          </div>
        </div>

        {/* 선택 옵션 */}
        <div className="main-options-container">
          <div className="main-options-section">
            <div className="main-option-text">
              <div className="main-option-icon">
                <img src={pickUpIcon} alt="Pickup" />
              </div>
              <div className="main-option-p">
                <p>Pickup</p>
                <p>Order Ahead</p>
              </div>
            </div>
            <div className="main-option-bar">
              <img src={pickUpOrderAhead} alt="OptionBar" />
            </div>
          </div>
          <div className="main-options-section">
            <div className="main-option-text">
              <div className="main-option-icon">
                <img src={deliveryIcon} alt="Delivery" />
              </div>
              <div className="main-option-p">
                <p>Delivery</p>
                <p>To Your Home</p>
              </div>
            </div>
            <div className="main-option-bar">
              <img src={deliveryToYourHome} alt="OptionBar" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
