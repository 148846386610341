import queryString from "query-string";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Footer from "../../components/Footer";

const FooterContainer = ({
  handleAddToCart,
  handlePayment,
  totalPrice = 0,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { uuid } = useParams();
  const { tableId } = queryString.parse(location.search);

  // 현재 경로에 따라 버튼 텍스트와 클릭 이벤트 결정
  const isCartPage = location.pathname.includes("/cart");
  const buttonText = isCartPage ? "메뉴 추가" : "장바구니 담기";

  const handleButtonClick = () => {
    if (isCartPage) {
      navigate(`/${uuid}/menu?tableId=${tableId}`);
    } else {
      handleAddToCart();
    }
  };

  return (
    <Footer
      totalPrice={totalPrice}
      buttonText={buttonText}
      onButtonClick={handleButtonClick}
      onPaymentClick={handlePayment}
    />
  );
};

export default FooterContainer;
