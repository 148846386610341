// 예시로, 액세스 토큰을 관리하는 파일입니다.
export const requestNewAccessToken = () => {
  return (dispatch) => {
    // 서버에서 토큰을 받아오는 로직 (간단히 예시)
    setTimeout(() => {
      dispatch({ type: "SET_ACCESS_TOKEN", payload: "newAccessToken123" });
    }, 1000);
  };
};

export const getAccessToken = (state) =>
  state.oauth ? state.oauth.accessToken : null;
