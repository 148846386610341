import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const PaymentResult = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // `returnUrl`에서 전달된 쿼리 파라미터 추출
  const queryParams = new URLSearchParams(location.search);
  const orderNumber = queryParams.get("orderNumber");

  useEffect(() => {
    const fetchPaymentResult = async () => {
      console.log("결제 결과를 가져오는 요청 시작"); // 요청 시작 확인

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/payment/result?orderNumber=${orderNumber}`
        );

        console.log("응답 상태 코드:", response.status); // 상태 코드 확인
        if (!response.ok) {
          throw new Error(`결제 결과 요청 실패. 상태 코드: ${response.status}`);
        }

        const data = await response.json();
        console.log("결제 결과 데이터:", data); // 결과 데이터 출력

        if (data.status === "success") {
          console.log("결제가 성공적으로 완료되었습니다.");
          navigate("/payment-success"); // 성공 페이지로 이동
        } else {
          console.error("결제가 실패했습니다.");
          navigate("/payment-failure"); // 실패 페이지로 이동
        }
      } catch (err) {
        console.error("결제 결과 요청 중 오류 발생:", err);
        navigate("/payment-failure"); // 실패 페이지로 이동
      }
    };

    if (orderNumber) {
      fetchPaymentResult();
    } else {
      console.error("orderNumber가 없습니다. 결제 결과 요청 불가.");
      navigate("/payment-failure");
    }
  }, [orderNumber, navigate]);

  return (
    <div>
      <h1>결제 결과 처리 중...</h1>
      <p>잠시만 기다려 주세요.</p>
    </div>
  );
};

export default PaymentResult;
