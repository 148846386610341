import React, { Component } from "react";
import { connect } from "react-redux";
import * as menuActions from "../modules/menu";
import { requestNewAccessToken, getAccessToken } from "../modules/OAuth";
import Loading from "./Loading";
import sampleData from "../db/data.json"; // JSON 파일을 import

class LoadingContainer extends Component {
  state = {
    loading: true,
  };

  // 컴포넌트가 마운트될 때 액세스 토큰 요청
  // componentDidMount() {
  //   const { requestToken: askOAuthToken } = this.props;
  //   console.log("componentDidMount: 액세스 토큰 요청 시작");
  //   // askOAuthToken(); 토큰 요청 시
  //   this.moveToNextPage();
  // }

  // 로컬 데이터 로딩 (import된 sampleData 사용)
  loadLocalData = () => {
    console.log("loadLocalData: 로컬 데이터 로딩 중");
    this.setState({ loading: false });
    this.loadShopData(sampleData); // sampleData 사용
  };

  // 로딩 후 다음 페이지로 전환
  moveToNextPage = () => {
    let { shopId, tableId } = this.props;
    console.log("moveToNextPage: shopId =", shopId, "tableId =", tableId);

    if (shopId === null || shopId === undefined) {
      const goToDemo = window.confirm("테스트");
      if (!goToDemo) {
        console.log("moveToNextPage: 테스트 모드로 넘어가지 않음");
        return;
      }
      shopId = 2;
      tableId = 999;
      console.log(
        "moveToNextPage: 테스트 모드로 shopId =",
        shopId,
        "tableId =",
        tableId
      );
    }
    this.loadLocalData(); // 로컬 데이터 로딩

    const { getTableId } = this.props;
    getTableId(tableId);
  };

  loadShopData(shopData) {
    const { getStoreInfo, getCategories, getMenus, onLoadFinished } =
      this.props;

    console.log("loadShopData: 상점 데이터 로딩 시작");
    // 로컬 데이터로 shop 정보 로딩
    getStoreInfo(shopData);
    getCategories(shopData.categories);
    getMenus(shopData.items);

    onLoadFinished();
  }

  render() {
    // const { accessToken } = this.props;
    const { loading } = this.state;

    const done = true; // 강제로 done을 true로 설정

    console.log(
      "render: LoadingContainer 렌더링 중, done =",
      done,
      "loading =",
      loading
    );

    return <Loading done={done} moveToNextPage={this.moveToNextPage} />;
  }
}

const mapStateToProps = (state) => {
  console.log("mapStateToProps: 액세스 토큰 가져오는 중");
  return {
    accessToken: getAccessToken(state), // getAccessToken을 통해 토큰을 받아옴
  };
};

const mapDispatchToProps = {
  requestToken: requestNewAccessToken,
  getStoreInfo: menuActions.getStoreInfo,
  getCategories: menuActions.getCategories,
  getMenus: menuActions.getMenus,
  getTableId: menuActions.getTableId,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoadingContainer);
