import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import "./MenuItem.css";
import queryString from "query-string";

const MenuItem = ({ item }) => {
  const navigate = useNavigate();
  const { uuid } = useParams();
  const location = useLocation();
  const { tableId } = queryString.parse(location.search); // tableId 쿼리 파라미터 추출

  useEffect(() => {
    console.log("MenuItem 렌더링됨");
    console.log("Item 데이터:", item);
  }, [item]);

  const handleClick = () => {
    console.log("메뉴 아이템 클릭됨:", item.name);
    navigate(`/${uuid}/${item.name}?tableId=${tableId}`);
  };

  return (
    <div className="menu-item" onClick={handleClick}>
      <div className="menu-item-details">
        <h4 className="menu-item-name">{item.name}</h4>
        <p className="menu-item-desc">{item.description}</p>
        <p className="menu-item-price">￦{item.price.toLocaleString()}</p>
      </div>
      <div className="menu-item-img-container">
        <img
          src={item.imgUrl || "/img/기본이미지.png"}
          alt={item.name}
          className="menu-item-img"
          onError={() => console.error("이미지 로드 실패:", item.imgUrl)}
        />
      </div>
    </div>
  );
};

export default MenuItem;
