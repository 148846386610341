import React from "react";
import { useNavigate } from "react-router-dom";
import "./PaymentResult.css"; // 공통 스타일 파일 사용

const PaymentSuccess = () => {
  const navigate = useNavigate();

  return (
    <div className="payment-result-container success">
      <h1>결제가 성공적으로 완료되었습니다!</h1>
      <p>주문이 정상적으로 접수되었습니다. 감사합니다.</p>
      <button onClick={() => navigate("/")} className="result-button">
        홈으로 이동
      </button>
    </div>
  );
};

export default PaymentSuccess;
