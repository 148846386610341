import { useLocation } from "react-router-dom";
import "./Cart.css";
import { useEffect, useState } from "react";
import queryString from "query-string";
import deleteIcon from "../assets/images/delete.png";
import FooterContainer from "../containers/common/FooterContainer";
import HeaderContainer from "../containers/common/HeaderContainer";

const Cart = ({ cartItems, setCartItems }) => {
  const location = useLocation();
  const { tableId } = queryString.parse(location.search);
  const [memo, setMemo] = useState("");

  const API_URL = process.env.REACT_APP_API_URL;

  // 장바구니 총 금액 계산 함수
  const calculateTotalPrice = () => {
    return cartItems.reduce((total, item) => total + item.totalPrice, 0);
  };

  // 주문하기(결제하기) 핸들러
  const handlePayment = () => {
    const orderNumber = `ORD-${Date.now()}`;
    const amount = calculateTotalPrice().toString();
    const firstItemName = cartItems[0]?.name || "No Items";
    const additionalItemCount = cartItems.length - 1;
    const itemName =
      additionalItemCount > 0
        ? `${firstItemName} 외 ${additionalItemCount}개`
        : firstItemName;
    const returnUrl = `${window.location.origin}/payment-result`;

    const data = {
      merchantId: "standard_test",
      itemName: itemName,
      amount: amount,
      userName: "table " + tableId,
      orderNumber: orderNumber,
      returnUrl: returnUrl,
    };

    console.log("결제 요청 데이터:", data);

    if (window.goPayupPay) {
      window.goPayupPay({
        ...data,
        successCallback: function (result) {
          console.log("결제 성공:", result);
          alert("결제가 성공적으로 완료되었습니다!");
        },
        errorCallback: function (error) {
          console.error("결제 실패:", error);
          alert("결제에 실패했습니다. 다시 시도해주세요.");
        },
      });
    } else {
      console.error("PayUp 결제 스크립트가 로드되지 않았습니다.");
    }
  };

  // 장바구니 항목 삭제 함수
  const handleRemoveItem = async (index) => {
    const itemToRemove = cartItems[index];
    try {
      const response = await fetch(`${API_URL}/cart/${itemToRemove.id}`, {
        method: "DELETE",
      });

      if (!response.ok) {
        throw new Error("장바구니 항목 삭제 실패");
      }

      setCartItems((prevItems) => prevItems.filter((_, i) => i !== index));
      alert("장바구니 항목이 삭제되었습니다!");
    } catch (error) {
      console.error("Error:", error);
      alert("서버와 통신 중 문제가 발생했습니다.");
    }
  };

  useEffect(() => {
    const fetchCartItems = async () => {
      try {
        const response = await fetch(`${API_URL}/cart`);
        if (!response.ok) {
          throw new Error("장바구니 데이터를 불러오는 데 실패했습니다.");
        }
        const items = await response.json();
        setCartItems(items);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchCartItems();
  }, [setCartItems, API_URL]);

  return (
    <div>
      <HeaderContainer cartItems={cartItems} setCartItems={setCartItems} />
      <div className="cart">
        <h2 className="cart-title">Basket</h2>
        <div className="cart-container">
          {cartItems.map((item, index) => (
            <div key={index} className="cart-item">
              <img
                src={item.imgUrl}
                alt={item.name}
                className="cart-item-img"
              />
              <div className="cart-item-details">
                <p className="cart-item-name">{item.name}</p>
                <p className="cart-item-price">
                  ￦{item.totalPrice.toLocaleString()}
                </p>
              </div>
              <div
                className="cart-item-remove"
                onClick={() => handleRemoveItem(index)}
              >
                <img src={deleteIcon} alt="삭제" />
              </div>
            </div>
          ))}
          <div className="cart-summary">
            <div className="cart-tip">
              <button className="tip-button">Tip</button>
              <button className="tip-button active">5%</button>
              <button className="tip-button">10%</button>
              <button className="tip-button">15%</button>
            </div>
            <div className="cart-total">
              <p>총 가격</p>
              <h3>￦{calculateTotalPrice().toLocaleString()}</h3>
            </div>
          </div>
        </div>
        <div className="cart-info">
          <div className="cart-info-box">
            <p>Table Location</p>
            <h4>{tableId}</h4>
          </div>
          <div className="cart-info-box">
            <p>Nickname</p>
            <h4>Ben</h4>
          </div>
          <div className="cart-info-box cart-memo-box">
            <p>Memo</p>
            <textarea
              className="cart-memo"
              placeholder="Please serve all the ordered food at once"
              value={memo}
              onChange={(e) => setMemo(e.target.value)}
            />
          </div>
        </div>
      </div>
      <FooterContainer handlePayment={handlePayment} totalPrice={5000} />
    </div>
  );
};

export default Cart;
