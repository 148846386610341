import { useEffect, useRef, useState } from "react";
import data from "../db/data2.json";
import "./ScrollMenu.css";
import MenuItem from "../component/MenuItem";
import HeaderContainer from "../containers/common/HeaderContainer";

const ScrollMenu = ({ cartItems, setCartItems }) => {
  const categoryRefs = useRef({}); // 카테고리별 참조 저장
  const menuRef = useRef(null); // 카테고리 메뉴 참조
  const [selectedCategory, setSelectedCategory] = useState(""); // 활성화된 카테고리 상태
  const currentCategoryRef = useRef(""); // 현재 활성화된 카테고리 상태
  const isClicking = useRef(false); // 클릭 이벤트 플래그

  const headerHeight = 100; // 헤더 고정 높이
  const categoryMenuHeight = 66; // 카테고리 메뉴 고정 높이

  // 초기 설정: 첫 카테고리를 활성화
  useEffect(() => {
    setSelectedCategory(data.categories[0]);
    currentCategoryRef.current = data.categories[0];
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  // 카테고리 클릭 시 해당 위치로 이동
  const handleCategoryClick = (category) => {
    isClicking.current = true; // 클릭 중임을 명시
    setSelectedCategory(category);
    currentCategoryRef.current = category; // 현재 활성화 상태 업데이트

    if (categoryRefs.current[category]) {
      const offsetTop =
        categoryRefs.current[category].offsetTop -
        (headerHeight + categoryMenuHeight); // 고정된 높이 고려
      window.scrollTo({
        top: offsetTop,
        behavior: "smooth",
      });

      // 가로 스크롤 메뉴를 클릭한 버튼으로 이동
      const activeButton = menuRef.current.querySelector(
        `.category-button[data-category="${category}"]`
      );
      if (activeButton) {
        const buttonLeft = activeButton.offsetLeft;
        const buttonWidth = activeButton.offsetWidth;
        const menuWidth = menuRef.current.offsetWidth;

        const targetScrollLeft = buttonLeft - menuWidth / 2 + buttonWidth / 2;
        menuRef.current.scrollTo({
          left: Math.max(0, targetScrollLeft),
          behavior: "smooth",
        });
      }
    }

    // 클릭 동작이 끝난 후 Observer 활성화
    setTimeout(() => {
      isClicking.current = false;
    }, 600);
  };

  // IntersectionObserver로 스크롤 중 현재 카테고리 감지
  useEffect(() => {
    const observerOptions = {
      root: null, // 뷰포트 기준
      rootMargin: `-${headerHeight + categoryMenuHeight}px 0px -20% 0px`, // 헤더와 카테고리 메뉴 높이 보정
      threshold: 0, // 0% 이상 화면에 들어왔을 때 감지
    };

    const observerCallback = (entries) => {
      if (isClicking.current) return; // 클릭 중이면 Observer 무시

      let mostVisibleCategory = null;
      let mostVisibleDistance = Infinity;

      // 뷰포트 중심 계산
      const viewportCenter = window.scrollY + window.innerHeight / 2;

      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const elementCenter =
            entry.target.offsetTop + entry.target.offsetHeight / 2;
          const distanceToCenter = Math.abs(viewportCenter - elementCenter);

          // 뷰포트 중심과 가장 가까운 카테고리 선택
          if (distanceToCenter < mostVisibleDistance) {
            mostVisibleDistance = distanceToCenter;
            mostVisibleCategory = entry.target.dataset.category;
          }
        }
      });

      if (
        mostVisibleCategory &&
        mostVisibleCategory !== currentCategoryRef.current
      ) {
        currentCategoryRef.current = mostVisibleCategory;
        setSelectedCategory(mostVisibleCategory);

        // 가로 스크롤 메뉴 동기화
        const activeButton = menuRef.current.querySelector(
          `.category-button[data-category="${mostVisibleCategory}"]`
        );
        if (activeButton) {
          const buttonLeft = activeButton.offsetLeft;
          const buttonWidth = activeButton.offsetWidth;
          const menuWidth = menuRef.current.offsetWidth;

          const targetScrollLeft = buttonLeft - menuWidth / 2 + buttonWidth / 2;
          menuRef.current.scrollTo({
            left: Math.max(0, targetScrollLeft),
            behavior: "smooth",
          });
        }
      }
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    Object.keys(categoryRefs.current).forEach((category) => {
      const element = categoryRefs.current[category];
      if (element) observer.observe(element);
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  // 스크롤 끝에 도달했을 때 마지막 카테고리 활성화
  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + window.scrollY >=
        document.documentElement.scrollHeight
      ) {
        // 문서의 끝에 도달한 경우
        const lastCategory = data.categories[data.categories.length - 1];
        if (lastCategory !== currentCategoryRef.current) {
          currentCategoryRef.current = lastCategory;
          setSelectedCategory(lastCategory);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <HeaderContainer cartItems={cartItems} setCartItems={setCartItems} />
      {/* 가로 스크롤 메뉴 */}
      <div className="category-scroll-menu" ref={menuRef}>
        {data.categories.map((category, index) => (
          <button
            key={index}
            data-category={category}
            onClick={() => handleCategoryClick(category)}
            className={`category-button ${
              selectedCategory === category ? "active" : ""
            }`}
          >
            {category}
          </button>
        ))}
      </div>

      {/* 테이블 오더 */}
      <div className="main-table-section">
        <div className="main-table-text">
          <div className="main-table-icon">
            <img src="/img2/fork.png" alt="Fork" />
          </div>
          <div className="main-table-p">
            <p>Eating a meal</p>
            <p>Order at Your Table</p>
          </div>
        </div>
        <div className="scrollmenu-img">
          <img src="/img2/OrderTable.png" alt="tableBar" />
        </div>
      </div>

      {/* 모든 카테고리와 메뉴 아이템 */}
      <div className="menu-items">
        {data.categories.map((category, index) => (
          <div
            key={index}
            ref={(el) => (categoryRefs.current[category] = el)} // 각 카테고리 참조 저장
            data-category={category} // 데이터 속성 추가
            className="menu-category"
          >
            <h2 className="category-title">{category}</h2>
            {(data.items[category] || []).map((item, index) => (
              <MenuItem key={index} item={item} />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ScrollMenu;
