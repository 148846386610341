import styles from "./styles.module.css";
import cartIcon from "../../assets/images/cart.png";
import backIcon from "../../assets/images/backIcon.png";

const Header = ({
  showBackButton,
  totalQuantity,
  onGoBack,
  onGoMain,
  onCartClick,
}) => {
  return (
    <header className={styles.header}>
      {/* 뒤로가기 버튼 조건부 렌더링 */}
      <div
        className={`${styles["back-icon-container"]} ${
          showBackButton ? "" : styles.hidden
        }`}
      >
        <img
          src={backIcon}
          className={styles["back-icon"]}
          onClick={onGoBack}
          alt="뒤로가기 아이콘"
        />
      </div>
      <div className={styles["store-logo"]} onClick={onGoMain}>
        <img src={"/img2/storeLogo.png"} alt="가게 로고" />
      </div>
      <div className={styles["cart-icon-container"]}>
        <img
          src={cartIcon}
          className={styles["cart-icon"]}
          onClick={onCartClick}
          alt="장바구니 아이콘"
        />
        {totalQuantity > 0 && (
          <span className={styles["cart-badge"]}>{totalQuantity}</span>
        )}
      </div>
    </header>
  );
};

export default Header;
