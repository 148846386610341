import React, { useState } from "react";
import "./CustomAlert.css";

const CustomAlert = ({ message, onClose }) => {
  const handleOverlayClick = (e) => {
    if (e.target.className === "custom-alert-overlay") {
      onClose(); // 오버레이 클릭 시 알림 닫기
    }
  };

  return (
    <div className="custom-alert-overlay" onClick={handleOverlayClick}>
      <div className="custom-alert">
        <p>{message}</p>
        <button onClick={onClose}>확인</button>
      </div>
    </div>
  );
};

export const useCustomAlert = () => {
  const [alertMessage, setAlertMessage] = useState(null);

  const showAlert = (message) => {
    setAlertMessage(message);
  };

  const closeAlert = () => {
    setAlertMessage(null);
  };

  const AlertComponent = alertMessage ? (
    <CustomAlert message={alertMessage} onClose={closeAlert} />
  ) : null;

  return { showAlert, AlertComponent };
};
