import React from "react";
import ReactDOM from "react-dom/client"; // React 18 사용
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import { logger } from "redux-logger";
import { thunk } from "redux-thunk";
import App from "./App";
import reducers from "./modules/menu"; // 리듀서
import "./index.css";
import { useCustomAlert } from "./component/CustomAlert";

// Redux store 생성
const store = createStore(
  reducers,
  applyMiddleware(thunk, logger) // 미들웨어 설정
);

window.payupPaymentSubmit = function (payForm) {
  console.log("payupPaymentSubmit 호출됨");
  console.log("폼 데이터:", new FormData(payForm));

  return;
};
const Home = () => {
  const { showAlert, AlertComponent } = useCustomAlert();

  // window.alert 대체
  React.useEffect(() => {
    window.alert = (message) => {
      showAlert(message);
    };
  }, [showAlert]);

  return (
    <>
      <Provider store={store}>
        <App />
      </Provider>
      {AlertComponent}
    </>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Home />);
