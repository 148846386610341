import { useLocation, useNavigate, useParams } from "react-router-dom";
import data from "../db/data2.json";
import "./MenuItemDetail.css";
import { useCallback, useEffect, useState } from "react";
import queryString from "query-string";
import FooterContainer from "../containers/common/FooterContainer";
import HeaderContainer from "../containers/common/HeaderContainer";

const MenuItemDetail = ({ addToCart, cartItems, setCartItems }) => {
  const navigate = useNavigate();
  const { itemName, uuid } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0); // 맨 위로 스크롤
  }, []);

  // 데이터에서 아이템을 검색
  const item = Object.values(data.items)
    .flat()
    .find((menuItem) => menuItem.name === itemName);

  useEffect(() => {
    console.log("MenuItemDetail 렌더링됨");
    console.log("아이템 데이터:", item);
  }, [item]);

  // item.options객체 안 hotOrIce, syrupOptions, shotOptions를
  // 쉽게 사용할 수 있도록
  // 구조 분해 할당 하는 것
  const { hotOrIce, syrupOptions, shotOptions } = item.options || {};

  const [selectedHotOrIce, setSelectedHotOrIce] = useState("Hot");
  const [selectedSyrupOptions, setSelectedSyrupOptions] = useState("");
  const [selectedShotOptions, setSelectedShotOptions] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [totalPrice, setTotalPrice] = useState(item.price || 0);
  const location = useLocation();
  const { tableId } = queryString.parse(location.search); // tableId 쿼리 파라미터 추출
  const API_URL = process.env.REACT_APP_API_URL;

  // 수량 증가 함수 (최대 99개로 제한)
  const handleQuantityIncrease = () =>
    setQuantity((prevQuantity) => {
      if (prevQuantity >= 99) {
        alert("수량은 최대 99개까지 가능합니다.");
        return prevQuantity;
      }
      return prevQuantity + 1;
    });

  // 수량 감소 함수(최소 1로 제한)
  const handleQuantityDecrease = () => {
    setQuantity((prevQuantity) => {
      if (prevQuantity <= 1) {
        alert("수량은 1개 이상이어야 합니다.");
        return prevQuantity;
      }
      return prevQuantity - 1;
    });
  };

  // 총 주문 금액 계산 함수
  const calculateTotalPrice = useCallback(() => {
    let total = item.price;
    console.log("기본 가격:", item.price);

    // 선택한 시럽 가격 추가
    if (selectedSyrupOptions) {
      const selectedSyrup = syrupOptions.find(
        (syrup) => syrup.name === selectedSyrupOptions
      );
      if (selectedSyrup) {
        total += selectedSyrup.additionalCost;
        console.log(
          `시럽 추가(${selectedSyrup.name}):`,
          selectedSyrup.additionalCost
        );
      }
    }
    // 선택한 샷 가격 추가
    if (selectedShotOptions) {
      const selectedShot = shotOptions.find(
        (shot) => shot.name === selectedShotOptions
      );
      if (selectedShot) {
        total += selectedShot.additionalCost;
        console.log(
          `샷 추가(${selectedShot.name}):`,
          selectedShot.additionalCost
        );
      }
    }
    // 수량 반영
    console.log("최종 금액:", total * quantity);
    return total * quantity;
  }, [
    item.price,
    quantity,
    selectedShotOptions,
    selectedSyrupOptions,
    shotOptions,
    syrupOptions,
  ]);

  // 옵션이나 수량이 바뀔 때마다 총 가격 계산
  useEffect(() => {
    setTotalPrice(calculateTotalPrice());
  }, [
    selectedHotOrIce,
    selectedSyrupOptions,
    selectedShotOptions,
    quantity,
    calculateTotalPrice,
  ]);

  // 라디오 버튼 선택 취소 기능
  const handleRadioButtonClick = (value, type) => {
    console.log(`옵션 변경: ${type} - ${value}`);
    if (type === "hotOrIce") {
      setSelectedHotOrIce((prev) => (prev === value ? "" : value)); // 같은 값을 클릭하면 해제
    } else if (type === "syrupOptions") {
      setSelectedSyrupOptions((prev) => (prev === value ? "" : value)); // 같은 값을 클릭하면 해제
    } else if (type === "shotOptions") {
      setSelectedShotOptions((prev) => (prev === value ? "" : value)); // 같은 값을 클릭하면 해제
    }
  };

  const addItemToCartAPI = async (cartItem) => {
    try {
      // 먼저 서버에서 장바구니 데이터를 가져옵니다
      const response = await fetch(`${API_URL}/cart`);
      const cartItemsFromServer = await response.json();

      // 이미 같은 메뉴와 옵션을 가진 아이템이 있는지 확인
      const existingItemIndex = cartItemsFromServer.findIndex(
        (item) =>
          item.name === cartItem.name &&
          JSON.stringify(item.options) === JSON.stringify(cartItem.options)
      );

      if (existingItemIndex !== -1) {
        // 이미 존재하는 아이템이 있으면, 수량과 가격을 업데이트
        const existingItem = cartItemsFromServer[existingItemIndex];
        const updatedItem = {
          ...existingItem,
          quantity: existingItem.quantity + cartItem.quantity,
          totalPrice: existingItem.totalPrice + cartItem.totalPrice,
        };

        // 업데이트된 장바구니 아이템을 서버에 PATCH 요청으로 보냅니다
        const updateResponse = await fetch(
          `${API_URL}/cart/${existingItem.id}`,
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(updatedItem),
          }
        );

        if (!updateResponse.ok) {
          throw new Error("아이템 업데이트 실패");
        }

        console.log("아이템 업데이트 완료:", updatedItem);
      } else {
        // 기존 아이템이 없다면 새 아이템을 추가
        const addResponse = await fetch(`${API_URL}/cart`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(cartItem),
        });

        if (!addResponse.ok) {
          throw new Error("아이템 추가 실패");
        }

        const result = await addResponse.json();
        console.log("새 아이템 추가:", result);
      }
    } catch (error) {
      //console.error("Error:", error);
      //alert("Mock API와 통신 중 문제가 발생했습니다.");
    }
  };

  const handleAddToCart = () => {
    if (hotOrIce && hotOrIce.length > 0 && !selectedHotOrIce) {
      alert("Hot 또는 Ice 옵션을 선택해 주세요.");
      return;
    }

    const cartItem = {
      name: item.name,
      quantity,
      totalPrice,
      options: {},
      imgUrl: item.imgUrl,
    };

    // 선택된 옵션만 `options` 객체에 추가
    if (hotOrIce && hotOrIce.length > 0 && selectedHotOrIce) {
      cartItem.options.hotOrIce = selectedHotOrIce; // 'Hot' 또는 'Ice'와 같은 선택된 값
    }
    if (selectedSyrupOptions) {
      cartItem.options.syrup = selectedSyrupOptions; // 선택된 시럽 이름
    }
    if (selectedShotOptions) {
      cartItem.options.shot = selectedShotOptions; // 선택된 샷 이름
    }

    console.log("장바구니 추가 아이템:", cartItem);

    addToCart(cartItem); // 부모 컴포넌트의 addToCart 함수 호출
    addItemToCartAPI(cartItem); // API로 장바구니 추가
    navigate(`/${uuid}/menu?tableId=${tableId}`); // 메뉴 페이지로 이동
  };

  // 주문하기(결제하기) 핸들러
  const handlePayment = () => {
    const orderNumber = `ORD-${Date.now()}`; // 고유 주문번호 생성
    const amount = calculateTotalPrice(); // 총 결제 금액

    // itemName 생성
    const firstItemName = item.name || "No Items"; // 첫 번째 메뉴 이름 // 나머지 메뉴 개수
    const itemName =
      quantity - 1 > 0
        ? `${firstItemName} 외 ${quantity - 1}개`
        : firstItemName;

    const returnUrl = `${window.location.origin}/payment-result`;

    const data = {
      merchantId: "standard_test",
      itemName: itemName,
      amount: amount.toString(),
      userName: "table" + tableId,
      orderNumber: orderNumber,
      returnUrl: returnUrl,
    };
    console.log("결제 요청 데이터:", data);

    // PayUp 결제창 호출
    if (window.goPayupPay) {
      window.goPayupPay(data);
    } else {
      console.error("PayUp 결제 스크립트가 로드되지 않았습니다.");
    }
  };

  return (
    <div>
      <HeaderContainer cartItems={cartItems} setCartItems={setCartItems} />
      <div className="menu-item-detail">
        <img
          src={item.imgUrl || "/img/기본이미지.png"}
          alt={item.name}
          className="menu-item-detail-image"
        />
        <h2 className="menu-name">{item.name}</h2>
        <hr></hr>
        <div className="menu-price">
          <p>가격</p>
          <p>￦{item.price.toLocaleString()}</p>
        </div>
        <hr></hr>

        <div className="menu-item-detail-options">
          {/* Hot/Ice 옵션(라디오 버튼) */}
          {hotOrIce && hotOrIce.length > 0 && (
            <div>
              <p>Hot / Ice</p>
              <p className="max-select-message">(최대 1개 선택 가능)</p>
              {hotOrIce.map((hotIce, index) => (
                <label key={index} className="options">
                  <div>
                    <input
                      type="radio"
                      name="hotOrIce"
                      value={hotIce}
                      checked={selectedHotOrIce === hotIce}
                      onClick={() => handleRadioButtonClick(hotIce, "hotOrIce")} // 선택 변경
                      onChange={() => {}} // React 경고 메시지 해결
                    />
                    <span>{hotIce}</span>
                  </div>
                </label>
              ))}
            </div>
          )}
          {/* 시럽 옵션(라디오 버튼) */}
          {syrupOptions && syrupOptions.length > 0 && (
            <div>
              <p>시럽 추가</p>
              <p className="max-select-message">(최대 1개 선택 가능)</p>
              {syrupOptions.map((syrup, index) => (
                <label key={index} className="options">
                  <div>
                    <input
                      type="radio"
                      name="syrupOptions"
                      value={syrup.name}
                      checked={selectedSyrupOptions === syrup.name}
                      onClick={() =>
                        handleRadioButtonClick(syrup.name, "syrupOptions")
                      } // 선택 변경
                      onChange={() => {}} // React 경고 메시지 해결
                    />
                    <span>{syrup.name}</span>
                  </div>
                  <span>+{syrup.additionalCost}원</span>
                </label>
              ))}
            </div>
          )}
          {/* 샷 옵션(라디오 버튼) */}
          {shotOptions && shotOptions.length > 0 && (
            <div>
              <p>에스프레소샷 추가</p>
              <p className="max-select-message">(최대 1개 선택 가능)</p>
              {shotOptions.map((shot, index) => (
                <label key={index} className="options">
                  <div>
                    <input
                      type="radio"
                      name="shotOptions"
                      value={shot.name}
                      checked={selectedShotOptions === shot.name}
                      onClick={() =>
                        handleRadioButtonClick(shot.name, "shotOptions")
                      } // 선택 변경
                      onChange={() => {}} // React 경고 메시지 해결
                    />
                    <span>{shot.name}</span>
                  </div>
                  <span>+{shot.additionalCost}원</span>
                </label>
              ))}
            </div>
          )}
        </div>
        <hr></hr>

        {/* 수량 조절 영역 */}
        <div className="menu-quantity">
          <p>수량</p>
          <div>
            <button onClick={handleQuantityDecrease} className="quantity-btn">
              -
            </button>
            <div className="quantity">{quantity}</div>
            <button onClick={handleQuantityIncrease} className="quantity-btn">
              +
            </button>
          </div>
        </div>
        <hr></hr>
        <div className="menu-total-price">
          <p>총 주문 금액</p>
          <p className="total-Price">￦{totalPrice.toLocaleString()}</p>
        </div>
      </div>
      <FooterContainer
        handleAddToCart={handleAddToCart}
        handlePayment={handlePayment}
      />
    </div>
  );
};

export default MenuItemDetail;
