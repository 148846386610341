import { useCallback, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import queryString from "query-string";
import Header from "../../components/Header";

const HeaderContainer = ({ cartItems, setCartItems }) => {
  const navigate = useNavigate();
  const { uuid } = useParams();
  const location = useLocation(); // 현재 경로 확인
  const { tableId } = queryString.parse(location.search);
  const API_URL = process.env.REACT_APP_API_URL;

  // 뒤로가기 버튼 표시 여부
  const showBackButton = location.pathname !== `/${uuid}/main`; // Main.js 경로 제외

  // API에서 장바구니 데이터 가져오기
  const fetchCartItems = useCallback(async () => {
    try {
      const response = await fetch(`${API_URL}/cart`);
      const data = await response.json();
      setCartItems(data);
    } catch (error) {
      console.error("장바구니 데이터 로드 에러:", error);
    }
  }, [setCartItems, API_URL]);

  // 장바구니에 담긴 전체 수량 계산
  const totalQuantity = cartItems.reduce((acc, item) => acc + item.quantity, 0);

  // storename 클릭 시 첫 화면 이동
  const goMain = () => {
    navigate(`/${uuid}/menu?tableId=${tableId}`);
  };

  // 장바구니 아이콘 클릭 시 이동
  const handleCartClick = () => {
    navigate(`/${uuid}/cart?tableId=${tableId}`);
  };

  // 뒤로가기 버튼 클릭 시
  const goBack = () => {
    navigate(-1); // 이전 페이지로 이동
  };

  useEffect(() => {
    fetchCartItems(); // 컴포넌트 마운트 시 API 호출
  }, [fetchCartItems]);

  return (
    <Header
      showBackButton={showBackButton}
      totalQuantity={totalQuantity}
      onGoBack={goBack}
      onGoMain={goMain}
      onCartClick={handleCartClick}
    />
  );
};

export default HeaderContainer;
