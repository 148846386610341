import menuData from "../db/data.json";

// 초기 상태 정의
const initialState = {
  categories: [],
  items: {},
  shopInfo: null,
  tableId: null,
  shopName: "",
  shopId: null,
  menus: {},
};

// 액션 타입 정의
const GET_CATEGORIES = "GET_CATEGORIES";
const GET_ITEMS_BY_CATEGORY = "GET_ITEMS_BY_CATEGORY";
const GET_ITEM_BY_NAME = "GET_ITEM_BY_NAME";
const GET_ITEM_OPTIONS = "GET_ITEM_OPTIONS";
const GET_STORE_INFO = "GET_STORE_INFO";
const GET_STORE_NAME = "GET_STORE_NAME";
const GET_MENUS = "GET_MENUS";
const SET_TABLE_ID = "SET_TABLE_ID";

// 리듀서
const menuReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CATEGORIES:
      return { ...state, categories: action.categories };
    case GET_ITEMS_BY_CATEGORY:
      return {
        ...state,
        items: { ...state.items, [action.category]: action.items },
      };
    case GET_ITEM_BY_NAME:
      return { ...state, item: action.item };
    case GET_ITEM_OPTIONS:
      return { ...state, itemOptions: action.options };
    case GET_STORE_INFO:
      return { ...state, shopInfo: action.shopInfo };
    case GET_STORE_NAME:
      return { ...state, shopName: action.shopName };
    case GET_MENUS:
      return { ...state, menus: action.menus };
    case SET_TABLE_ID:
      return { ...state, tableId: action.tableId };
    default:
      return state;
  }
};

export default menuReducer;

// 카테고리 목록을 반환하는 함수
export const getCategories = () => {
  return {
    type: GET_CATEGORIES,
    categories: menuData.categories,
  };
};

// 특정 카테고리에 해당하는 메뉴 아이템을 반환하는 함수
export const getItemsByCategory = (category) => {
  return {
    type: GET_ITEMS_BY_CATEGORY,
    items: menuData.items[category] || [],
  };
};

// 특정 메뉴 항목을 이름으로 검색하는 함수
export const getItemByName = (name) => {
  return {
    type: GET_ITEM_BY_NAME,
    item:
      Object.values(menuData.items)
        .flat()
        .find((item) => item.name === name) || null,
  };
};

// 메뉴 아이템에 추가된 옵션을 반환하는 함수
export const getItemOptions = (category, itemName) => {
  return {
    type: GET_ITEM_OPTIONS,
    options:
      menuData.items[category]?.find((item) => item.name === itemName)
        ?.options || {},
  };
};

// 가게 정보 반환
export const getStoreInfo = (storeId) => {
  // storeId에 해당하는 가게 정보 찾기
  if (menuData.storeId === storeId) {
    return {
      type: GET_STORE_INFO,
      shopInfo: {
        storeId: menuData.storeId,
        storeName: menuData.storeName,
        categories: menuData.categories,
        items: menuData.items,
      },
    };
  } else {
    return {
      type: GET_STORE_INFO,
      shopInfo: null,
    };
  }
};

// 가게 이름 반환
export const getStoreName = (storeId) => {
  return {
    type: GET_STORE_NAME,
    shopName:
      menuData.storeId === storeId ? menuData.storeName : "Unknown Shop",
  };
};

// 가게 메뉴 반환
export const getMenus = (storeId) => {
  return {
    type: GET_MENUS,
    menus: menuData.storeId === storeId ? menuData.items : [],
  };
};

// 좌석 ID 설정 액션
export const getTableId = (tableId) => {
  return {
    type: SET_TABLE_ID,
    tableId,
  };
};
