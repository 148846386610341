import React from "react";
import { useNavigate } from "react-router-dom";
import "./PaymentResult.css"; // 공통 스타일 파일 사용

const PaymentFailure = () => {
  const navigate = useNavigate();

  return (
    <div className="payment-result-container failure">
      <h1>결제가 실패했습니다.</h1>
      <p>결제 중 문제가 발생했습니다. 다시 시도해 주세요.</p>
      <button onClick={() => navigate("/cart")} className="result-button">
        장바구니로 이동
      </button>
    </div>
  );
};

export default PaymentFailure;
