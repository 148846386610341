import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useParams,
} from "react-router-dom";
import "./App.css";
import "./font/font.css";
import ScrollMenu from "./pages/ScrollMenuPage";
import MenuItemDetail from "./pages/MenuItemDetailPage";
import Cart from "./pages/CartPage";
import LoadingPage from "./pages/LoadingPage";
import ErrPageHandler from "./pages/ErrPage";
import Main from "./pages/MainPage";
import PaymentResult from "./pages/PaymentResultPage";
import PaymentSuccess from "./pages/PaymentSuccessPage";
import PaymentFailure from "./pages/PaymentFailurePage";

// UUID 정규식
const uuidRegex =
  /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;

// UUID 검증 컴포넌트
const UuidValidationWrapper = ({ children }) => {
  const { uuid } = useParams(); // 이제 uuid를 shopId로 사용
  return uuidRegex.test(uuid) ? children : <Navigate to="/error" />;
};

function App() {
  const [cartItems, setCartItems] = useState([]);

  // 장바구니에 추가
  const addToCart = (newItem) => {
    setCartItems((prevItems) => {
      const existingItemIndex = prevItems.findIndex(
        (item) =>
          item.name === newItem.name &&
          item.options.hotOrIce === newItem.options.hotOrIce &&
          item.options.syrup === newItem.options.syrup &&
          item.options.shot === newItem.options.shot
      );
      if (existingItemIndex !== -1) {
        return prevItems.map((item, index) =>
          index === existingItemIndex
            ? {
                ...item,
                quantity: item.quantity + newItem.quantity,
                totalPrice: item.totalPrice + newItem.totalPrice,
              }
            : item
        );
      } else {
        return [...prevItems, newItem];
      }
    });
    alert(`${newItem.name}가 장바구니에 담겼습니다.`);
  };

  return (
    <Router>
      <div className="App">
        <Routes>
          {/* UUID 경로 */}
          <Route
            path="/:uuid" // uuid를 shopId로 사용
            element={
              <UuidValidationWrapper>
                <LoadingPage />
              </UuidValidationWrapper>
            }
          />

          <Route
            path="/:uuid/main"
            element={
              <UuidValidationWrapper>
                <Main cartItems={cartItems} setCartItems={setCartItems} />
              </UuidValidationWrapper>
            }
          />

          {/* 메뉴와 관련된 경로 */}
          <Route
            path="/:uuid/menu"
            element={
              <UuidValidationWrapper>
                <ScrollMenu cartItems={cartItems} setCartItems={setCartItems} />
              </UuidValidationWrapper>
            }
          />

          {/* 메뉴 카테고리 */}
          <Route
            path="/:uuid/menu/:categoryName"
            element={
              <UuidValidationWrapper>
                <ScrollMenu cartItems={cartItems} setCartItems={setCartItems} />
              </UuidValidationWrapper>
            }
          />

          {/* 메뉴 아이템 상세 */}
          <Route
            path="/:uuid/:itemName"
            element={
              <UuidValidationWrapper>
                <MenuItemDetail
                  addToCart={addToCart}
                  cartItems={cartItems}
                  setCartItems={setCartItems}
                />
              </UuidValidationWrapper>
            }
          />

          {/* 장바구니 페이지 */}
          <Route
            path="/:uuid/cart"
            element={
              <UuidValidationWrapper>
                <Cart cartItems={cartItems} setCartItems={setCartItems} />
              </UuidValidationWrapper>
            }
          />
          {/* 결제 결과 */}
          <Route
            path="/payment-result"
            element={<PaymentResult useMock={true} />}
          />
          <Route path="/payment-success" element={<PaymentSuccess />} />
          <Route path="/payment-failure" element={<PaymentFailure />} />

          {/* 에러 페이지 */}
          <Route path="/error" element={<ErrPageHandler />} />

          {/* 모든 경로와 매칭되지 않으면 에러 페이지로 이동 */}
          <Route path="*" element={<Navigate to="/error" />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
